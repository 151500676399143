import React from "react";

function TabFixation() {
  return (
    <div className="content active-content">
      <p>
        La fixation de la parabole est importante dans l'installation. En effet,
        c'est l'élément qui est soumis aux conditions météorologiques, et plus
        particulièrement au vent. Si le diamètre du mât est trop faible, la
        force exercée par le vent sur la parabole risque de le faire plier
        légèrement, ce qui dégradera la réception jusqu'au point fatal de
        non-réception.
        <br />
        <br />
        Il existe 3 types de fixation :<br />
      </p>
      <ul>
        <li>
          le mât droit, de diamètre 60 de préférence, pouvant avoir une longueur
          comprise entre 60cm à 90cm, dont le point d'ancrage au sol s'effectue
          par 4 vis
        </li>
        <li>
          le mât coudé, toujours de diamètre 60, permettant de déporter la
          parabole par rapport à un mur, dont le point d'ancrage se fait contre
          le mur par 4 vis
        </li>
        <li>le support balcon</li>
      </ul>
    </div>
  );
}

export default TabFixation;
