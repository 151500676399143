import React, { FunctionComponent } from "react";
import img_pire_astra from "../../assets/images/sat/astra.gif";
import img_pire_hotbird from "../../assets/images/sat/hotbird.gif";
import img_pire_eutelsat from "../../assets/images/sat/telecom2c.gif";
import Satellite from "../../models/satellite";

type Props = {
  satellite: Satellite;
};

const InfosCard: FunctionComponent<Props> = ({ satellite }) => {
  return (
    <div className="row">
      <div id={satellite.name} className="col-lg-12 mb-3">
        <div className="card h-100">
          <h2 className="card-header">{satellite.name}</h2>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <p className="card-text">
                  <h3>Informations</h3>
                </p>
                <p className="card-text">
                  {satellite.nav === "eutelsat" ? (
                    <>
                      {satellite.baseline}
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  La position orbitale du satellite {satellite.name} est de{" "}
                  {satellite.orbital}.<br />
                  Ce satellite permet de recevoir les chaînes de l'opérateur{" "}
                  {satellite.tvchannels}.
                  <br />
                  Pour plus de renseignement :{" "}
                  <a href={satellite.website} target="_blank" rel="noopener">
                    site d'{satellite.name}
                  </a>
                  .
                </p>
                <p className="card-text">
                  Voici la zone de couverture du satellite avec le diamètre de
                  parabole à utiliser.
                </p>
              </div>
              <div
                className="col-lg-4 mt-3"
                role="img"
                aria-label="Carte de couverture du satellite"
              >
                {satellite.nav == "astra" && (
                  <img
                    src={img_pire_astra}
                    alt="couverture du satellite Astra"
                    className="container-fluid"
                    width="334px"
                    height="320px"
                  />
                )}
                {satellite.nav == "hotbird" && (
                  <img
                    src={img_pire_hotbird}
                    alt="couverture du satellite Hotbird"
                    className="container-fluid"
                    width="334px"
                    height="320px"
                  />
                )}
                {satellite.nav == "eutelsat" && (
                  <img
                    src={img_pire_eutelsat}
                    alt="couverture du satellite Eutelsat"
                    className="container-fluid"
                    width="334px"
                    height="320px"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfosCard;
