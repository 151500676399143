import React from "react";
import Img_ficheF from "../../assets/images/conseil/fiche-f.png";

function TabFiche() {
  
  return (
    <div className="content active-content">
      <p>
        Il existe deux types de{" "}
        <a className="tooltip" href="#">
          <span className="tooltiptext">
            <img
              src={Img_ficheF}
              width="200px"
              height="200px"
              alt="Fiche F à visser"
            ></img>
          </span>
          fiche F
        </a>
        , la fiche F à visser et la fiche F à sertir.
        <br />
        Le plus fréquemment utilisée est la fiche F à visser sur le câble ( voir{" "}
        <a href="/pose/cable">montage</a>
        ).
        <br />
        Il vous en faudra 2 de type mâle afin de les visser à chaque extremité
        du câble.
      </p>
    </div>
  );
}

export default TabFiche;
