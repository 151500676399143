import React from "react";

const TabPoseinstallation = () => {
  return (
    <div className="tab-pane fade" role="tabpanel" id="instal">
      <p>
        1) Installation du support de la parabole : installer le support mural (
        ou le mât ) à l'emplacement choisi, le fixer avec les vis et les
        chevilles adaptées au matériau de la zone de fixation. Attention, le
        support doit être fixé sur une surface plane, verticale pour un support
        au mur ou horizontale pour un support au sol car le mât doit resté bien
        vertical pour que l'inclinaison soit la plus précise possible.
      </p>
      <p>
        2) Positionner l'antenne sur son support, orienter la parabole vers le
        SUD à l'aide de la boussole, puis la faire pivoter de la valeur donnée
        pour l'azimuth de votre département ( <a href="/installation">?</a>{" "}
        ).
      </p>
      <p>
        3) Si vous installez la parabole sur un support existant déjà en place (
        mât d'antenne hertzienne par exemple ), attention de bien vérifier la
        soliditée de la fixation.
      </p>
    </div>
  );
};

export default TabPoseinstallation;
