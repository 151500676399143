import MapFrance from "./map-france";

export const Departements: MapFrance[] = [
  {
    dept: 1,
    dept_name: "Ain",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 2,
    dept_name: "Aisne",
    region_name: "Hauts-de-France",
  },
  {
    dept: 3,
    dept_name: "Allier",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 4,
    dept_name: "Alpes-de-Haute-Provence",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 5,
    dept_name: "Hautes-Alpes",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 6,
    dept_name: "Alpes-Maritimes",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 7,
    dept_name: "Ardèche",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 8,
    dept_name: "Ardennes",
    region_name: "Grand Est",
  },
  {
    dept: 9,
    dept_name: "Ariège",
    region_name: "Occitanie",
  },
  {
    dept: 10,
    dept_name: "Aube",
    region_name: "Grand Est",
  },
  {
    dept: 11,
    dept_name: "Aude",
    region_name: "Occitanie",
  },
  {
    dept: 12,
    dept_name: "Aveyron",
    region_name: "Occitanie",
  },
  {
    dept: 13,
    dept_name: "Bouches-du-Rhône",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 14,
    dept_name: "Calvados",
    region_name: "Normandie",
  },
  {
    dept: 15,
    dept_name: "Cantal",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 16,
    dept_name: "Charente",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 17,
    dept_name: "Charente-Maritime",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 18,
    dept_name: "Cher",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 19,
    dept_name: "Corrèze",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 20,
    dept_name: "Corse",
    region_name: "Corse",
  },
  {
    dept: 21,
    dept_name: "Côte-d'Or",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 22,
    dept_name: "Côtes-d'Armor",
    region_name: "Bretagne",
  },
  {
    dept: 23,
    dept_name: "Creuse",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 24,
    dept_name: "Dordogne",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 25,
    dept_name: "Doubs",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 26,
    dept_name: "Drôme",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 27,
    dept_name: "Eure",
    region_name: "Normandie",
  },
  {
    dept: 28,
    dept_name: "Eure-et-Loir",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 29,
    dept_name: "Finistère",
    region_name: "Bretagne",
  },
  {
    dept: 30,
    dept_name: "Gard",
    region_name: "Occitanie",
  },
  {
    dept: 31,
    dept_name: "Haute-Garonne",
    region_name: "Occitanie",
  },
  {
    dept: 32,
    dept_name: "Gers",
    region_name: "Occitanie",
  },
  {
    dept: 33,
    dept_name: "Gironde",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 34,
    dept_name: "Hérault",
    region_name: "Occitanie",
  },
  {
    dept: 35,
    dept_name: "Ille-et-Vilaine",
    region_name: "Bretagne",
  },
  {
    dept: 36,
    dept_name: "Indre",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 37,
    dept_name: "Indre-et-Loire",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 38,
    dept_name: "Isère",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 39,
    dept_name: "Jura",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 40,
    dept_name: "Landes",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 41,
    dept_name: "Loir-et-Cher",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 42,
    dept_name: "Loire",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 43,
    dept_name: "Haute-Loire",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 44,
    dept_name: "Loire-Atlantique",
    region_name: "Pays de la Loire",
  },
  {
    dept: 45,
    dept_name: "Loiret",
    region_name: "Centre-Val de Loire",
  },
  {
    dept: 46,
    dept_name: "Lot",
    region_name: "Occitanie",
  },
  {
    dept: 47,
    dept_name: "Lot-et-Garonne",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 48,
    dept_name: "Lozère",
    region_name: "Occitanie",
  },
  {
    dept: 49,
    dept_name: "Maine-et-Loire",
    region_name: "Pays de la Loire",
  },
  {
    dept: 50,
    dept_name: "Manche",
    region_name: "Normandie",
  },
  {
    dept: 51,
    dept_name: "Marne",
    region_name: "Grand Est",
  },
  {
    dept: 52,
    dept_name: "Haute-Marne",
    region_name: "Grand Est",
  },
  {
    dept: 53,
    dept_name: "Mayenne",
    region_name: "Pays de la Loire",
  },
  {
    dept: 54,
    dept_name: "Meurthe-et-Moselle",
    region_name: "Grand Est",
  },
  {
    dept: 55,
    dept_name: "Meuse",
    region_name: "Grand Est",
  },
  {
    dept: 56,
    dept_name: "Morbihan",
    region_name: "Bretagne",
  },
  {
    dept: 57,
    dept_name: "Moselle",
    region_name: "Grand Est",
  },
  {
    dept: 58,
    dept_name: "Nièvre",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 59,
    dept_name: "Nord",
    region_name: "Hauts-de-France",
  },
  {
    dept: 60,
    dept_name: "Oise",
    region_name: "Hauts-de-France",
  },
  {
    dept: 61,
    dept_name: "Orne",
    region_name: "Normandie",
  },
  {
    dept: 62,
    dept_name: "Pas-de-Calais",
    region_name: "Hauts-de-France",
  },
  {
    dept: 63,
    dept_name: "Puy-de-Dôme",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 64,
    dept_name: "Pyrénées-Atlantiques",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 65,
    dept_name: "Hautes-Pyrénées",
    region_name: "Occitanie",
  },
  {
    dept: 66,
    dept_name: "Pyrénées-Orientales",
    region_name: "Occitanie",
  },
  {
    dept: 67,
    dept_name: "Bas-Rhin",
    region_name: "Grand Est",
  },
  {
    dept: 68,
    dept_name: "Haut-Rhin",
    region_name: "Grand Est",
  },
  {
    dept: 69,
    dept_name: "Rhône",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 70,
    dept_name: "Haute-Saône",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 71,
    dept_name: "Saône-et-Loire",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 72,
    dept_name: "Sarthe",
    region_name: "Pays de la Loire",
  },
  {
    dept: 73,
    dept_name: "Savoie",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 74,
    dept_name: "Haute-Savoie",
    region_name: "Auvergne-Rhône-Alpes",
  },
  {
    dept: 75,
    dept_name: "Paris",
    region_name: "Île-de-France",
  },
  {
    dept: 76,
    dept_name: "Seine-Maritime",
    region_name: "Normandie",
  },
  {
    dept: 77,
    dept_name: "Seine-et-Marne",
    region_name: "Île-de-France",
  },
  {
    dept: 78,
    dept_name: "Yvelines",
    region_name: "Île-de-France",
  },
  {
    dept: 79,
    dept_name: "Deux-Sèvres",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 80,
    dept_name: "Somme",
    region_name: "Hauts-de-France",
  },
  {
    dept: 81,
    dept_name: "Tarn",
    region_name: "Occitanie",
  },
  {
    dept: 82,
    dept_name: "Tarn-et-Garonne",
    region_name: "Occitanie",
  },
  {
    dept: 83,
    dept_name: "Var",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 84,
    dept_name: "Vaucluse",
    region_name: "Provence-Alpes-Côte d'Azur",
  },
  {
    dept: 85,
    dept_name: "Vendée",
    region_name: "Pays de la Loire",
  },
  {
    dept: 86,
    dept_name: "Vienne",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 87,
    dept_name: "Haute-Vienne",
    region_name: "Nouvelle-Aquitaine",
  },
  {
    dept: 88,
    dept_name: "Vosges",
    region_name: "Grand Est",
  },
  {
    dept: 89,
    dept_name: "Yonne",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 90,
    dept_name: "Territoire de Belfort",
    region_name: "Bourgogne-Franche-Comté",
  },
  {
    dept: 91,
    dept_name: "Essonne",
    region_name: "Île-de-France",
  },
  {
    dept: 92,
    dept_name: "Hauts-de-Seine",
    region_name: "Île-de-France",
  },
  {
    dept: 93,
    dept_name: "Seine-Saint-Denis",
    region_name: "Île-de-France",
  },
  {
    dept: 94,
    dept_name: "Val-de-Marne",
    region_name: "Île-de-France",
  },
  {
    dept: 95,
    dept_name: "Val-d'Oise",
    region_name: "Île-de-France",
  },
];