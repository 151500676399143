import React, { FunctionComponent } from "react";
import Satellite from "../../models/satellite";

type Props = {
  satellite: Satellite
};

const InstallCard: FunctionComponent<Props> = ({satellite}) => {
  return (
    <div id={satellite.name} className="col-lg-4 mb-5">
      <div className="card h-100">
        <h2 className="card-header">{satellite.name}</h2>
        <div className="card-body">
          <p className="card-text">Position orbitale : {satellite.orbital}</p>
          <p className="card-text">
            <small>Chaînes {satellite.tvchannels}</small>
          </p>
        </div>
        <div className="card-footer text-center">
          <a
            href={`/installation/${satellite.nav}`}
            className="btn btn-primary"
          >
            Choisir
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstallCard;
