import React, { FunctionComponent, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ImgDownload from "../../assets/images/pdf-document.svg";
import UrlPdfArticle166457 from "../../assets/documents/Article 1 de la loi N66-457.pdf";
import UrlPdfArticle1671171 from "../../assets/documents/Article 1 decret N67-1171 du 22dec1967.pdf";

const Droit: FunctionComponent = () => {
  const [showMod1, setMod1Show] = useState(false);
  const handleMod1Close = () => setMod1Show(false);
  const handleMod1Show = () => setMod1Show(true);
  const [showMod2, setMod2Show] = useState(false);
  const handleMod2Close = () => setMod2Show(false);
  const handleMod2Show = () => setMod2Show(true);
  return (
    <div className="container">
      <Breadcrumb
        title_page="Le droit à l'antenne"
        title="Droit à l'antenne"
        referent_title=""
        referent_path=""
      />

      {/* EDITO DE LA PAGE */}
      <div className="row">
        {/* paragraphe 1 */}
        <div className="col-lg-12">
          <p>
            Le droit à l'antenne constitue aujourd'hui un principe bien établi.
            La liberté de réception audiovisuelle a été aménagée par la loi
            N°66-457 du 2 juillet 1966, puis a été modifié par 2 fois par le
            décret d'applications N°67-1171 du 22 décembre 1967 et par le décret
            N°93-533 du 27 mars 1993. Le droit de réception audiovisuelle
            s'applique à tout citoyen, qu'il habite en maison individuelle, en
            logement collectif ou qu'il soit locataire ou copropriétaire.
          </p>
          {/* Button modal 1 */}
          <Button
            type="button"
            onClick={handleMod1Show}
            variant="primary"
            aria-label="Voir l'article 1 de la loi N° 66-457"
          >
            Voir l'article de loi
          </Button>
          {/* Modal 1 */}
          <Modal
            show={showMod1}
            onHide={handleMod1Close}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Article 1 de la loi N° 66-457</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <article>
                <p>
                  Le propriétaire d'un immeuble ne peut, nonobstant toute
                  convention contraire, même antérieurement conclue, s'opposer,
                  sans motif sérieux et légitime à l'installation, à l'entretien
                  ou au remplacement ainsi qu'au raccordement au réseau interne
                  à l'immeuble, aux frais d'un ou plusieurs locataires ou
                  occupant de bonne foi, que ces derniers soient personnes
                  physiques ou morales, d'une antenne extérieure réceptrice de
                  radiodiffusion ou réceptrice et émettrice de télécommunication
                  fixe.
                </p>
                <p>
                  L'offre, faite par le propriétaire, de raccordement soit à une
                  antenne collective, soit à un réseau interne à l'immeuble qui
                  fournissent un service collectif dont le contenu et la
                  tarification sont définis par un accord collectif pris en
                  application de l'article 42 de la loi n° 86-1290 du 23
                  décembre 1986 tendant à favoriser l'investissement locatif,
                  l'accession à la propriété de logements sociaux et le
                  développement de l'offre foncière et constitue, notamment, un
                  motif sérieux et légitime de s'opposer à l'installation ou au
                  remplacement d'une antenne individuelle.
                </p>
                <p>
                  Les modalités de remplacement d'une antenne collective par un
                  autre mode de réception des programmes sont déterminées par un
                  accord collectif pris en application de l'article 42 de la loi
                  n° 86-1290 du 23 décembre 1986 précitée.
                </p>
                <p>
                  Toutefois, le propriétaire d'un immeuble ne peut s'opposer,
                  sans motif sérieux et légitime, à l'installation, au
                  remplacement ou à l'entretien des antennes individuelles,
                  émettrices et réceptrices, nécessaires au bon fonctionnement
                  de stations du service amateur autorisées conformément à la
                  réglementation en vigueur. Les bénéficiaires sont
                  responsables, chacun en ce qui le concerne, des travaux
                  d'installation, d'entretien ou de remplacement et des
                  conséquences que pourrait comporter la présence des antennes
                  en cause.
                </p>
              </article>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleMod1Close}
                aria-label="Close"
              >
                Fermer
              </Button>
              <a href={UrlPdfArticle166457} target="_blank" rel="noreferrer">
                <img
                  src={ImgDownload}
                  className="bouton_pdf"
                  alt="Ouvrir en PDF"
                />
              </a>
            </Modal.Footer>
          </Modal>
          <br />
          <br />
        </div>

        {/* paragraphe 2 */}
        <div className="col-lg-12">
          <p>
            Le locataire, ou l'occupant de bonne foi, avant de procéder aux
            travaux d'installation de l'antenne individuelle, doit effectuer une
            demande auprès du propriétaire de l'immeuble, ou a l'administrateur
            des biens de celui ci ou encore au syndic de copropriété qui
            administre l'immeuble où le demandeur habite, et cela par lettre
            recommandée avec AR à laquelle sont joints la liste des services de
            télévision requis, normalement reçus sur le site, un descriptif
            détaillé des travaux projetés, ainsi qu'un plan ou un schémas de
            ceux-ci. Tout cela afin de respecter bien entendu le décret
            N°67-1171 du 22 décembre 1967.
          </p>
          {/* Button modal 2 */}
          <Button
            type="button"
            onClick={handleMod2Show}
            variant="primary"
            aria-label="Voir l'article 1 du décret N°67-1171 du 22 décembre 1967"
          >
            Voir l'article de loi
          </Button>
          {/* Modal 2 */}
          <Modal
            show={showMod2}
            onHide={handleMod2Close}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Article 1 du décret N°67-1171 du 22 décembre 1967
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <article>
                <p>
                  Avant de procéder aux travaux d'installation, d'entretien ou
                  de remplacement d'une antenne réceptrice de radiodiffusion
                  sonore ou de télévision, ou d'une antenne émettrice et
                  réceptrice d'une station d'amateur, ou aux travaux de
                  raccordement à un réseau câblé mentionnés par l'article 1er de
                  la loi no 66-457 du 2 juillet 1966 susvisée, le locataire ou
                  l'occupant de bonne foi doit en informer le propriétaire par
                  lettre recommandée avec demande d'avis de réception.
                </p>
                <p>
                  Une description détaillée des travaux à entreprendre est
                  jointe à cette notification, assortie s'il y a lieu d'un plan
                  ou d'un schéma, sauf si l'établissement de ce plan a été rendu
                  impossible du fait du propriétaire. La notification doit
                  indiquer également la nature du ou des services de
                  radiodiffusion sonore ou de télévision dont la réception
                  serait obtenue à l'aide de ladite antenne individuelle ou
                  dudit raccordement.
                </p>
              </article>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleMod2Close}
                aria-label="Close"
              >
                Fermer
              </Button>
              <a href={UrlPdfArticle1671171} target="_blank" rel="noreferrer">
                <img
                  src={ImgDownload}
                  className="bouton_pdf"
                  alt="Ouvrir en PDF"
                />
              </a>
            </Modal.Footer>
          </Modal>
          <br />
          <br />
        </div>

        {/* paragraphe 3 */}
        <div className="col-lg-12">
          <p>
            Le propriétaire de l'immeuble, ou a l'administrateur des biens de
            celui ci ou encore au syndic de copropriété qui administre
            l'immeuble où le demandeur habite saisie dans les trois mois qui
            suivent la réception de celle-ci le Tribunal d'Instance du lieu de
            l'immeuble, en fondant son opposition sur un motif sérieux et
            légitime, tels que l'installation d'une antenne collective (
            dispositions prévues par la loi de 1966 ) ou le raccordement via un
            réseau câblé ou afin de réserver l'esthétique de l'immeuble. Le
            tribunal d'instance statuera sur le bien-fondé de la demande à
            autoriser ou non la pose d'antenne. Si le propriétaire de
            l'immeuble, ou a l'administrateur des biens de celui ci ou encore au
            syndic de copropriété qui administre l'immeuble où le demandeur
            habite ne saisi pas le tribunal d'instance dans un délai de trois
            mois ou n'a pas installé l'antenne collective promise ou réalisé le
            raccordement au réseau câblé, le droit d'installer l'antenne par le
            locataire ou l'occupant de bonne foi sera réputé acquis.
            <br />
          </p>
          <br />
        </div>
        {/* FIN EDITO DE LA PAGE */}
      </div>
    </div>
  );
};

export default Droit;
