import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Landing from './containers/Landing/index';
import Droit from './containers/Droit/Droit';
import Conseil from './containers/Conseil/Conseil';
import Outils from './containers/Conseil/Outils';
import Materiel from './containers/Conseil/Materiel';
import Pose from './containers/Pose/Pose';
import Installation from './containers/Installation/Installation';
import InstallationDetails from './containers/Installation/InstallationDetail';
// import ReactGA from 'react-ga4';

// const TRACKING_ID = 'G-19BLW0S3LQ';
// ReactGA.initialize(TRACKING_ID);

function App() {
  // useEffect(() => {
  //   // ReactGA.pageview(window.location.pathname);
  //   ReactGA.send({ page_title: location.pathname });
  // }, []);

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/droit" element={<Droit />} />
          <Route path="/conseil" element={<Conseil />} />
          <Route path="/conseil/outils" element={<Outils />} />
          <Route path="/conseil/materiel" element={<Materiel />} />
          <Route path="/conseil/materiel/:nav" element={<Materiel />} />
          <Route path="/pose" element={<Pose />} />
          <Route path="/pose/:nav" element={<Pose />} />
          <Route path="/installation" element={<Installation />} />
          <Route path="/installation/:nav" element={<InstallationDetails />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
