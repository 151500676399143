import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Satellite from '../../models/satellite';
import { SATELLITES } from '../../models/database/mock-satellites';
// import SatelliteService from "../../services/satellite-service";
import InfosCard from '../../components/Cards/InfosCard';
import SearchCard from '../../components/Cards/SearchCard';

const InstallationDetail: FunctionComponent = () => {
  const { nav } = useParams();

  const [satellites, setSatellites] = useState<Satellite[]>([]);
  useEffect(() => {
    setSatellites(SATELLITES);
    // SatelliteService.getSatellites().then((satellites) =>
    //   setSatellites(satellites)
    // );
  }, []);

  return (
    <div className="container">
      {satellites
        .filter((satellite) => satellite.nav === nav)
        .map((satellite) => (
          <div key={nav}>
            <Breadcrumb
              title_page={`Les infos concernant ${satellite.name}`}
              title={satellite.name_breadcrumb}
              referent_title="Installation"
              referent_path="/installation"
            />
            <InfosCard satellite={satellite} />
            <SearchCard satellite={satellite} />
          </div>
        ))}
    </div>
  );
};

export default InstallationDetail;
