import React from "react";
import img_lnb_1 from "../../assets/images/conseil/lnb1.png";
import img_lnb_2 from "../../assets/images/conseil/lnb2.png";
import img_lnb_4 from "../../assets/images/conseil/lnb4.png";

function TabLNB() {
  return (
    <div className="content active-content">
      <p>
        La tête LNB est un des éléments clef qui permet de convertir les
        fréquences émises par le satellite en fréquences compréhensibles par le
        tuner du récepteur satellite. En effet le satellite émet dans une bande
        de fréquence appelée KU ( de 10700 MHz à 12750 MHz ) alors que le tuner
        des récepteurs acceptent des fréquences d'entrée de 950 MHz à 2150MHz (
        2050 MHz pour les anciens récepteurs).
      </p>
      <p>
        Pour faire cette conversion en fréquence, deux oscillateurs locaux,
        appelé OL, sont utilisés. Un à 9750 MHz ( pour la bande basse ) et
        l'autre à 10600 MHz ( pour la bande haute). Le passage entre les deux OL
        s'effectue par l'envoie d'un signal 22 kHz par le récepteur à la tête.
        Lorsque le signal est présent, c'est la bande haute qui est utilisé,
        lorsqu'il n'y a pas de signal, c'est la bande basse.
      </p>
      <p>
        Mais malheureusement, cela ne suffit pas. En effet, chaque fréquence
        peut être &quot; transportée &quot; entre le satellite et la tête LNB
        soit avec une polarisation Verticale, soit par une polarisation
        Horizontale. La tête se décompose en deux capteurs ( un horizontal et
        l'autre vertical ) et celle-ci a besoin de savoir lequel utiliser. A
        cause de cela, on envoie une tension à la tête afin de bien commuter sur
        l'un ou sur l'autre. Une tension de 18 volts sert pour le capteur
        horizontal et une tension de 13 volts pour le capteur vertical.
      </p>
      <p>En résumé, voici un petit tableau récapitulatif :</p>

      <div className="row">
        <table className="table table-perso">
          <thead>
            <tr>
              <th>Tension (Volt)</th>
              <th>22 kHz</th>
              <th>Bande reçue</th>
              <th>Fréquence (MHz)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>18</td>
              <td>Non</td>
              <td>Horizontale bande basse</td>
              <td>10700 à 11900</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Non</td>
              <td>Verticale bande basse</td>
              <td>10700 à 11900</td>
            </tr>
            <tr>
              <td>18</td>
              <td>Oui</td>
              <td>Horizontale bande haute</td>
              <td>11550 à 12750</td>
            </tr>
            <tr>
              <td>13</td>
              <td>Oui</td>
              <td>Verticale bande haute</td>
              <td>11550 à 12750</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <p>
        Il existe plusieurs modèles de tête, qui dépendent du type de
        l'installation. Pour une installation classique avec un seul récepteur,
        une tête{" "}
        <a className="tooltip" href="#">
          <span className="tooltiptext">
            <img
              src={img_lnb_1}
              width="200px"
              height="200px"
              alt="LNB Universelle"
            ></img>
          </span>
          LNB Universelle
        </a>
        .<br />
        Par contre pour brancher 2 terminaux, il faut utiliser une tête{" "}
        <a className="tooltip" href="#">
          <span className="tooltiptext">
            <img
              src={img_lnb_2}
              width="200px"
              height="200px"
              alt="LNB Twin"
            ></img>
          </span>
          TWIN
        </a>{" "}
        ( 2 sorties indépendantes ) et pour 3 ou 4 terminaux, une tête{" "}
        <a className="tooltip" href="#">
          <span className="tooltiptext">
            <img
              src={img_lnb_4}
              width="200px"
              height="200px"
              alt="LNB Quadtwin"
            ></img>
          </span>
          QUADTWIN
        </a>{" "}
        ( 4 sorties indépendantes ).
        <br />
        Attention ne pas confondre avec QUATTRO qui est une tête utilisée dans
        les installations collectives et dont chaque sortie correspond à une
        bande de frequence et à une polarisation différente !
      </p>
    </div>
  );
}

export default TabLNB;
