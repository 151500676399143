import React from "react";
import ImgPoseCab1 from "../../assets/images/pose/cab1.gif";
import ImgPoseCab2 from "../../assets/images/pose/cab1.gif";
import ImgPoseCab3 from "../../assets/images/pose/cab3.gif";
import ImgPoseCab4 from "../../assets/images/pose/cab4.gif";

const TabPosecable = () => {
  return (
    <div>
      <div className="tab-pane fade" role="tabpanel" id="cable">
        <div className="col-lg-12">
          <p>
            1) Préparation du câble coaxial : Choisir le cheminement du câble
            qui relira l'antenne au récepteur satellite ( pas plus de 30 mètres
            ). Installer le câble en prévoyant une longueur libre suffisante (
            environ 1 mètre ) aux deux extrémités. Eviter de trop courber le
            câble et ne jamais pincer, abîmer, percer ou pincer le câble.
          </p>
        </div>
        <div className="col-lg-12">
          <p>2) Montage des fiches F :</p>
          <div className="media">
            <div>
              <img
                src={ImgPoseCab1}
                alt="denuder le câble"
                width="150px"
                height="50px"
              ></img>
            </div>
            <div className="media-body">
              <p>
                Dénuder chaque extrémité, retirer la gaine extérieure sur 10 mm.
              </p>
            </div>
          </div>
          <div className="media">
            <div>
              <img
                src={ImgPoseCab2}
                alt="rabattre le câble"
                width="150px"
                height="50px"
              ></img>
            </div>
            <div className="media-body">
              <p>Rabattre la tresse de masse vers l'arriére.</p>
            </div>
          </div>
          <div className="media">
            <div>
              <img
                src={ImgPoseCab3}
                alt="retirer la gaine interne"
                width="150px"
                height="50px"
              ></img>
            </div>
            <div className="media-body">
              <p>
                Retirer la gaine interne sur 8 mm, l'âme du câble bien droite.
              </p>
            </div>
          </div>
          <div className="media">
            <div>
              <img
                src={ImgPoseCab4}
                alt="visser les fiches F"
                width="150px"
                height="50px"
              ></img>
            </div>
            <div className="media-body">
              <p>Visser les fiches F aux 2 extrémités du câble.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <br />
          <p>
            3) branchement du câble coaxial :<br />
            - côté parabole : insérer l'âme du câble dans le connecteur femelle
            de la tête, puis visser et serrer à la main.
            <br />- coté recepteur : insérer l'âme du câble dans le connecteur
            femelle marqué " ENTREE ANTENNE ", puis le visser et serrer à la
            main.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabPosecable;
