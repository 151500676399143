import React from 'react'

function BreadcrumbLevel1(props:any) {
    return (
        <ol className="breadcrumb">
  <li className="breadcrumb-item">
    <a href="../">Accueil</a>
    
  </li>
  <li className="breadcrumb-item active">{props.title}</li>
</ol>
    )
}

export default BreadcrumbLevel1
