import React from "react";
import BreadcrumbLevel1 from "./BreadcrumbLevel1";
import BreadcrumbLevel2 from "./BreadcrumbLevel2";

function Breadcrumb(props: any) {
  const referent_path = props.referent_path;

  return (
    <div>
      {/*  Fil d'ariane */}
      <h1 className="mt-4 mb-3">{props.title_page}</h1>
      {referent_path ? (
        <BreadcrumbLevel2
          title_page={props.title_page}
          title={props.title}
          referent_path={props.referent_path}
          referent_title={props.referent_title}
        />
      ) : (
        <BreadcrumbLevel1 title_page={props.title_page} title={props.title} />
      )}
      {/* FIN DU HEADER */}
    </div>
  );
}

export default Breadcrumb;
