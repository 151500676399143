import React from "react";

const TabPosemontage = () => {
  return (
    <div className="tab-pane fade show active" role="tabpanel" id="montage">
      <p>1) Assembler le réflecteur sur la monture, avec le bras.</p>
      <p>
        2) Régler l'angle d'élévation ( <a href="/installation">?</a> ).
      </p>
      <p>
        3) Monter la tête LNB sur son support, assembler la bride, serrer les
        vis légèrement sans bloquer.
      </p>
      <p>
        4) Placer l'ensemble " support + tête " à l'extrémité du bras de
        l'antenne.
      </p>
      <p>
        5) Tourner légèrement de 10° dans le sens des aiguilles d'une montre la
        tête dans son support (se positionner face à l'antenne).
      </p>
    </div>
  );
};

export default TabPosemontage;
