import React, { FunctionComponent, useState, useEffect } from 'react';
import Infosatellite from '../../models/infosatellite';
// import SatelliteService from "../../services/satellite-service";
import {
  INFOSASTRA,
  INFOSHOTBIRD,
  INFOSEUTELSAT,
} from '../../models/database/mock-infosatellite';

type Result = {
  dept: number;
  satname: string;
};

const ResultCard: FunctionComponent<Result> = (props) => {
  const dept = props.dept;
  const satname = props.satname;
  const droite = 'Ouest (la droite) ';
  const gauche = 'Est (la gauche) ';

  const [satinfos, setSatinfos] = useState<Infosatellite[]>([]);
  useEffect(() => {
    // fetch(`http://localhost:3002/${satname}`)
    // SatelliteService.getInfosatDept(satname, dept).then((satellites) =>
    //   setSatinfos(satellites)
    // );
    // console.log(satinfos.length);

    if (satname === 'astra') {
      setSatinfos(INFOSASTRA);
    }
    if (satname === 'hotbird') {
      setSatinfos(INFOSHOTBIRD);
    }
    if (satname === 'eutelsat') {
      setSatinfos(INFOSEUTELSAT);
    }
  }, [dept]);

  //  useEffect(() => {
  //    // fetch(`http://localhost:3002/${satname}`)
  //    SatelliteService.getInfosatellite(satname)
  //      .then((satellites) => setSatinfos(satellites))
  //  }, [satname]);

  return (
    <div className="pt-3">
      <div>
        {satinfos
          .filter((satinfo) => satinfo.id === dept)
          .map((satinfo) => (
            <div key={satinfo.id}>
              <p>Lélévation est de {satinfo.elevation}°.</p>
              <p>
                L'azimuth est de {satinfo.azimuth}° vers l'
                {satname === 'eutelsat' ? droite : gauche}
                par rapport au sud.
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ResultCard;
