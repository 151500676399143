import React from "react";

function TabCable() {
  return (
    <div className="content active-content">
      <p>
        El&eacute;ment de liaison entre l'antenne et le r&eacute;cepteur
        satellite, la c&acirc;ble coaxial ne peut &ecirc;tre choisi &agrave; la
        l&eacute;g&egrave;re, car de lui d&eacute;pend la perte du niveau du
        signal &eacute;mit de la t&ecirc;te LNB ainsi que la qualit&eacute; du
        signal re&ccedil;ut par le r&eacute;cepteur.
        <br />
        En France, la norme C90-132 ( instaur&eacute;e en 1992 ) permet de
        diff&eacute;rencier les c&acirc;bles entre eux, o&ugrave; sur ceux ci
        est indiqu&eacute;e une r&eacute;f&eacute;rence correspondant aux
        caract&eacute;ristiques du c&acirc;ble.
        <br />
        Le marquage sur le c&acirc;ble se d&eacute;compose de la sorte (ici on
        prend l'exemple d'un c&acirc;ble de type 19VAtCA) :
      </p>

      <div className="row">
        <table className="table table-secondary">
          <thead>
            <tr>
              <th>référence</th>
              <th>19</th>
              <th>V</th>
              <th>At</th>
              <th>C</th>
              <th>A</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>valeur possible</td>
              <td>XX </td>
              <td>P ou V</td>
              <td>Rc ou Rt ou At</td>
              <td>M ou C</td>
              <td>A</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p style={{ fontSize: "16px" }}>
        XX: att&eacute;nuation &agrave; 800 MHz
        <br />
        P : gaine ext&eacute;rieure en poly&eacute;thyl&egrave;ne,
        r&eacute;sistant aux UV, pour l'utilisation ext&eacute;rieure, de
        couleur noire
        <br />
        V : gaine ext&eacute;rieure en PVC, pour l'utilisation
        int&eacute;rieure, de couleur blanche
        <br />
        Rc : ruban en cuivre contrecoll&eacute;
        <br />
        Rt : ruban en cuivre + tresse en cuivre
        <br />
        At : ruban en aluminium - tresse en cuivre
        <br />
        M : poly&eacute;thyl&egrave;ne massif
        <br />
        C : poly&eacute;thyl&egrave;ne cellulaire
        <br />A : &acirc;me en acier cuivr&eacute;
      </p>
      <p>Alors quoi prendre ? Que veulent dire ces chiffres ?</p>
      <p>
        - L'att&eacute;nuation correspond &agrave; la perte d'un c&acirc;ble
        d'une longueur de 100m &agrave; une fr&eacute;quence de 800Mhz,
        exprim&eacute;e en dB. Plus le chiffre est petit, mieux cela est. Un
        c&acirc;ble en 21 aura une perte de 21db, un c&acirc;ble de 19 une perte
        de 19db et un de 17, une perte de 17db.
        <br />
        - P/V indique si le c&acirc;ble est plut&ocirc;t con&ccedil;u pour
        l'int&eacute;rieur ou l'ext&eacute;rieur.
        <br />
        - Pour le blindage du c&acirc;ble, il faut qu'il soit dot&eacute; d'un
        double raccordement pour &eacute;viter de d&eacute;grader le transport
        du signal, donc utiliser un c&acirc;ble At de pr&eacute;f&eacute;rence
        ou Rt.
        <br />
        - Pour les autres lettres, peut importe en fait mais vous aurez peut le
        choix en grande distribution.
        <br />
      </p>
      <p>
        Conclusion, un c&acirc;ble pour l'ext&eacute;rieur, 17 P At C, et pour
        l'int&eacute;rieur, 17 V At C.
      </p>
    </div>
  );
}

export default TabCable;
