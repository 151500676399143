import React from "react";

function TabParabole() {
  return (
    <div className="content active-content">
      <p>
        Le diamètre de la parabole dépend du satellite que l'on
        désire recevoir et du lieu où on se trouve. En effet,
        chaque satellite a une puissance d'émission plus ou moins
        élevée et est généralement destiné
        à émettre vers une région bien précise. A
        cause de cela, en fonction du lieu géographique, il sera
        nécessaire d'utiliser une parabole dont la taille peut varier
        afin de recevoir au mieux celui-ci.
      </p>
      <p>
        Pour nous aider, il existe des cartes qui détermine le niveau de
        réception du satellite concerné en fonction du lieu
        géographique. Le niveau de réception est en
        corrélation avec le diamètre de la parabole à
        utiliser.
      </p>
      <p>
        En ce qui concerne le matériel à utiliser pour la
        parabole, une simple parabole en acier suffit, sauf en milieu salin car
        celle-ci va, plus ou moins vite, rouiller.
      </p>
      <p>
        Reportez vous à la section "
        <a href="/installation">installation</a>" pour connaître la
        taille de la parabole en fonction du satellite désiré.
      </p>
    </div>
  );
}

export default TabParabole;
