import Infosatellite from "../infosatellite";

export const INFOSASTRA: Infosatellite[] = [
  {
    id: 1,
    dept: 1,
    dept_name: "Ain",
    elevation: 35,
    azimuth: 19,
  },
  {
    id: 2,
    dept: 2,
    dept_name: "Aines",
    elevation: 31,
    azimuth: 20,
  },
  {
    id: 3,
    dept: 3,
    dept_name: "Allier",
    elevation: 34,
    azimuth: 22,
  },
  {
    id: 4,
    dept: 4,
    dept_name: "Alpes Hautes Provence",
    elevation: 37,
    azimuth: 18,
  },
  {
    id: 5,
    dept: 5,
    dept_name: "Hautes Alpes",
    elevation: 36,
    azimuth: 18,
  },
  {
    id: 6,
    dept: 6,
    dept_name: "Alpes Maritimes",
    elevation: 38,
    azimuth: 17,
  },
  {
    id: 7,
    dept: 7,
    dept_name: "Ardèche",
    elevation: 36,
    azimuth: 21,
  },
  {
    id: 8,
    dept: 8,
    dept_name: "Ardennes",
    elevation: 31,
    azimuth: 19,
  },
  {
    id: 9,
    dept: 9,
    dept_name: "Ariège",
    elevation: 37,
    azimuth: 25,
  },
  {
    id: 10,
    dept: 10,
    dept_name: "Aube",
    elevation: 32,
    azimuth: 20,
  },
  {
    id: 11,
    dept: 11,
    dept_name: "Aude",
    elevation: 37,
    azimuth: 24,
  },
  {
    id: 12,
    dept: 12,
    dept_name: "Aveyron",
    elevation: 36,
    azimuth: 23,
  },
  {
    id: 13,
    dept: 13,
    dept_name: "Bouches du Rhône",
    elevation: 37,
    azimuth: 20,
  },
  {
    id: 14,
    dept: 14,
    dept_name: "Calvados",
    elevation: 30,
    azimuth: 26,
  },
  {
    id: 15,
    dept: 15,
    dept_name: "Cantal",
    elevation: 34,
    azimuth: 23,
  },
  {
    id: 16,
    dept: 16,
    dept_name: "Charente",
    elevation: 34,
    azimuth: 26,
  },
  {
    id: 17,
    dept: 17,
    dept_name: "Charente Maritime",
    elevation: 34,
    azimuth: 25,
  },
  {
    id: 18,
    dept: 18,
    dept_name: "Cher",
    elevation: 33,
    azimuth: 22,
  },
  {
    id: 19,
    dept: 19,
    dept_name: "Corrèze",
    elevation: 35,
    azimuth: 24,
  },
  {
    id: 20,
    dept: 20,
    dept_name: "Corse",
    elevation: 40,
    azimuth: 16,
  },
  {
    id: 21,
    dept: 21,
    dept_name: "Côtes d'Or",
    elevation: 33,
    azimuth: 20,
  },
  {
    id: 22,
    dept: 22,
    dept_name: "Côtes d'Armor",
    elevation: 30,
    azimuth: 29,
  },
  {
    id: 23,
    dept: 23,
    dept_name: "Creuse",
    elevation: 34,
    azimuth: 24,
  },
  {
    id: 24,
    dept: 24,
    dept_name: "Dordogne",
    elevation: 34,
    azimuth: 26,
  },
  {
    id: 25,
    dept: 25,
    dept_name: "Doubs",
    elevation: 34,
    azimuth: 17,
  },
  {
    id: 26,
    dept: 26,
    dept_name: "Drome",
    elevation: 36,
    azimuth: 20,
  },
  {
    id: 27,
    dept: 27,
    dept_name: "Eure",
    elevation: 31,
    azimuth: 24,
  },
  {
    id: 28,
    dept: 28,
    dept_name: "Eure et Loire",
    elevation: 31,
    azimuth: 24,
  },
  {
    id: 29,
    dept: 29,
    dept_name: "Finistère",
    elevation: 30,
    azimuth: 30,
  },
  {
    id: 30,
    dept: 30,
    dept_name: "Gard",
    elevation: 37,
    azimuth: 21,
  },
  {
    id: 31,
    dept: 31,
    dept_name: "Haute Garonne",
    elevation: 37,
    azimuth: 25,
  },
  {
    id: 32,
    dept: 32,
    dept_name: "Gers",
    elevation: 36,
    azimuth: 26,
  },
  {
    id: 33,
    dept: 33,
    dept_name: "Gironde",
    elevation: 34,
    azimuth: 27,
  },
  {
    id: 34,
    dept: 34,
    dept_name: "Hérault",
    elevation: 37,
    azimuth: 22,
  },
  {
    id: 35,
    dept: 35,
    dept_name: "Ille et Vilaine",
    elevation: 31,
    azimuth: 27,
  },
  {
    id: 36,
    dept: 36,
    dept_name: "Indre",
    elevation: 33,
    azimuth: 24,
  },
  {
    id: 37,
    dept: 37,
    dept_name: "Indre et Loire",
    elevation: 32,
    azimuth: 25,
  },
  {
    id: 38,
    dept: 38,
    dept_name: "Isère",
    elevation: 36,
    azimuth: 19,
  },
  {
    id: 39,
    dept: 39,
    dept_name: "Jura",
    elevation: 34,
    azimuth: 18,
  },
  {
    id: 40,
    dept: 40,
    dept_name: "Landes",
    elevation: 35,
    azimuth: 28,
  },
  {
    id: 41,
    dept: 41,
    dept_name: "Loire et Cher",
    elevation: 32,
    azimuth: 24,
  },
  {
    id: 42,
    dept: 42,
    dept_name: "Loire",
    elevation: 35,
    azimuth: 21,
  },
  {
    id: 43,
    dept: 43,
    dept_name: "Haute Loire",
    elevation: 36,
    azimuth: 19,
  },
  {
    id: 44,
    dept: 44,
    dept_name: "Loire Atlantique",
    elevation: 32,
    azimuth: 27,
  },
  {
    id: 45,
    dept: 45,
    dept_name: "Loiret",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 46,
    dept: 46,
    dept_name: "Lot",
    elevation: 35,
    azimuth: 25,
  },
  {
    id: 47,
    dept: 47,
    dept_name: "Lot et Garonne",
    elevation: 35,
    azimuth: 26,
  },
  {
    id: 48,
    dept: 48,
    dept_name: "Lozère",
    elevation: 36,
    azimuth: 22,
  },
  {
    id: 49,
    dept: 49,
    dept_name: "Maine et Loire",
    elevation: 32,
    azimuth: 26,
  },
  {
    id: 50,
    dept: 50,
    dept_name: "Manche",
    elevation: 30,
    azimuth: 27,
  },
  {
    id: 51,
    dept: 51,
    dept_name: "Marne",
    elevation: 31,
    azimuth: 20,
  },
  {
    id: 52,
    dept: 52,
    dept_name: "Haute Marne",
    elevation: 33,
    azimuth: 19,
  },
  {
    id: 53,
    dept: 53,
    dept_name: "Mayenne",
    elevation: 31,
    azimuth: 26,
  },
  {
    id: 54,
    dept: 54,
    dept_name: "Meurthe et Moselle",
    elevation: 32,
    azimuth: 20,
  },
  {
    id: 55,
    dept: 55,
    dept_name: "Meuse",
    elevation: 32,
    azimuth: 18,
  },
  {
    id: 56,
    dept: 56,
    dept_name: "Morbihan",
    elevation: 31,
    azimuth: 29,
  },
  {
    id: 57,
    dept: 57,
    dept_name: "Moselle",
    elevation: 32,
    azimuth: 17,
  },
  {
    id: 58,
    dept: 58,
    dept_name: "Nièvre",
    elevation: 33,
    azimuth: 21,
  },
  {
    id: 59,
    dept: 59,
    dept_name: "Nord",
    elevation: 30,
    azimuth: 21,
  },
  {
    id: 60,
    dept: 60,
    dept_name: "Oise",
    elevation: 31,
    azimuth: 22,
  },
  {
    id: 61,
    dept: 61,
    dept_name: "Orne",
    elevation: 31,
    azimuth: 25,
  },
  {
    id: 62,
    dept: 62,
    dept_name: "Pas de Calais",
    elevation: 30,
    azimuth: 22,
  },
  {
    id: 63,
    dept: 63,
    dept_name: "Puy de Dôme",
    elevation: 35,
    azimuth: 22,
  },
  {
    id: 64,
    dept: 64,
    dept_name: "Pyrénées Atlantique",
    elevation: 36,
    azimuth: 28,
  },
  {
    id: 65,
    dept: 65,
    dept_name: "Hautes Pyrénées",
    elevation: 37,
    azimuth: 27,
  },
  {
    id: 66,
    dept: 66,
    dept_name: "Pyrénées Orientales",
    elevation: 38,
    azimuth: 24,
  },
  {
    id: 67,
    dept: 67,
    dept_name: "Bas Rhin",
    elevation: 33,
    azimuth: 16,
  },
  {
    id: 68,
    dept: 68,
    dept_name: "Haut Rhin",
    elevation: 33,
    azimuth: 16,
  },
  {
    id: 69,
    dept: 69,
    dept_name: "Rhône",
    elevation: 35,
    azimuth: 20,
  },
  {
    id: 70,
    dept: 70,
    dept_name: "Haute Saône",
    elevation: 33,
    azimuth: 18,
  },
  {
    id: 71,
    dept: 71,
    dept_name: "Saône et Loire",
    elevation: 34,
    azimuth: 20,
  },
  {
    id: 72,
    dept: 72,
    dept_name: "Sarthe",
    elevation: 31,
    azimuth: 25,
  },
  {
    id: 73,
    dept: 73,
    dept_name: "Savoie",
    elevation: 36,
    azimuth: 18,
  },
  {
    id: 74,
    dept: 74,
    dept_name: "Haute Savoie",
    elevation: 35,
    azimuth: 18,
  },
  {
    id: 75,
    dept: 75,
    dept_name: "Paris",
    elevation: 31,
    azimuth: 22,
  },
  {
    id: 76,
    dept: 76,
    dept_name: "Seine Maritime",
    elevation: 30,
    azimuth: 24,
  },
  {
    id: 77,
    dept: 77,
    dept_name: "Seine et Marne",
    elevation: 32,
    azimuth: 21,
  },
  {
    id: 78,
    dept: 78,
    dept_name: "Yvelines",
    elevation: 31,
    azimuth: 23,
  },
  {
    id: 79,
    dept: 79,
    dept_name: "Deux-sèvres",
    elevation: 33,
    azimuth: 26,
  },
  {
    id: 80,
    dept: 80,
    dept_name: "Somme",
    elevation: 30,
    azimuth: 22,
  },
  {
    id: 81,
    dept: 81,
    dept_name: "Tarn",
    elevation: 36,
    azimuth: 24,
  },
  {
    id: 82,
    dept: 82,
    dept_name: "Tarn et Garonne",
    elevation: 36,
    azimuth: 25,
  },
  {
    id: 83,
    dept: 83,
    dept_name: "Var",
    elevation: 38,
    azimuth: 19,
  },
  {
    id: 84,
    dept: 84,
    dept_name: "Vaucluse",
    elevation: 37,
    azimuth: 20,
  },
  {
    id: 85,
    dept: 85,
    dept_name: "Vendée",
    elevation: 32,
    azimuth: 27,
  },
  {
    id: 86,
    dept: 86,
    dept_name: "Vienne",
    elevation: 33,
    azimuth: 25,
  },
  {
    id: 87,
    dept: 87,
    dept_name: "Haute Vienne",
    elevation: 35,
    azimuth: 22,
  },
  {
    id: 88,
    dept: 88,
    dept_name: "Vosges",
    elevation: 33,
    azimuth: 17,
  },
  {
    id: 89,
    dept: 89,
    dept_name: "Yonne",
    elevation: 33,
    azimuth: 21,
  },
  {
    id: 90,
    dept: 90,
    dept_name: "Belfort",
    elevation: 34,
    azimuth: 17,
  },
  {
    id: 91,
    dept: 91,
    dept_name: "Essonne",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 92,
    dept: 92,
    dept_name: "Haut de Seine",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 93,
    dept: 93,
    dept_name: "Seine Saint Denis",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 94,
    dept: 94,
    dept_name: "Val de Marne",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 95,
    dept: 95,
    dept_name: "Val d'Oise",
    elevation: 32,
    azimuth: 22,
  }
];
    
export const INFOSHOTBIRD: Infosatellite[] = [
  {
    id: 1,
    dept: 1,
    dept_name: "Ain",
    elevation: 37,
    azimuth: 11,
  },
  {
    id: 2,
    dept: 2,
    dept_name: "Aines",
    elevation: 33,
    azimuth: 12,
  },
  {
    id: 3,
    dept: 3,
    dept_name: "Allier",
    elevation: 36,
    azimuth: 14,
  },
  {
    id: 4,
    dept: 4,
    dept_name: "Alpes Hautes Provence",
    elevation: 39,
    azimuth: 10,
  },
  {
    id: 5,
    dept: 5,
    dept_name: "Hautes Alpes",
    elevation: 38,
    azimuth: 10,
  },
  {
    id: 6,
    dept: 6,
    dept_name: "Alpes Maritimes",
    elevation: 39,
    azimuth: 9,
  },
  {
    id: 7,
    dept: 7,
    dept_name: "Ardèche",
    elevation: 38,
    azimuth: 13,
  },
  {
    id: 8,
    dept: 8,
    dept_name: "Ardennes",
    elevation: 33,
    azimuth: 11,
  },
  {
    id: 9,
    dept: 9,
    dept_name: "Ariège",
    elevation: 39,
    azimuth: 17,
  },
  {
    id: 10,
    dept: 10,
    dept_name: "Aube",
    elevation: 34,
    azimuth: 12,
  },
  {
    id: 11,
    dept: 11,
    dept_name: "Aude",
    elevation: 39,
    azimuth: 13,
  },
  {
    id: 12,
    dept: 12,
    dept_name: "Aveyron",
    elevation: 38,
    azimuth: 15,
  },
  {
    id: 13,
    dept: 13,
    dept_name: "Bouches du Rhône",
    elevation: 39,
    azimuth: 11,
  },
  {
    id: 14,
    dept: 14,
    dept_name: "Calvados",
    elevation: 32,
    azimuth: 18,
  },
  {
    id: 15,
    dept: 15,
    dept_name: "Cantal",
    elevation: 36,
    azimuth: 14,
  },
  {
    id: 16,
    dept: 16,
    dept_name: "Charente",
    elevation: 36,
    azimuth: 18,
  },
  {
    id: 17,
    dept: 17,
    dept_name: "Charente Maritime",
    elevation: 36,
    azimuth: 17,
  },
  {
    id: 18,
    dept: 18,
    dept_name: "Cher",
    elevation: 35,
    azimuth: 14,
  },
  {
    id: 19,
    dept: 19,
    dept_name: "Corrèze",
    elevation: 37,
    azimuth: 16,
  },
  {
    id: 20,
    dept: 20,
    dept_name: "Corse",
    elevation: 41,
    azimuth: 7,
  },
  {
    id: 21,
    dept: 21,
    dept_name: "Côtes d'Or",
    elevation: 35,
    azimuth: 11,
  },
  {
    id: 22,
    dept: 22,
    dept_name: "Côtes d'Armor",
    elevation: 32,
    azimuth: 21,
  },
  {
    id: 23,
    dept: 23,
    dept_name: "Creuse",
    elevation: 36,
    azimuth: 15,
  },
  {
    id: 24,
    dept: 24,
    dept_name: "Dordogne",
    elevation: 37,
    azimuth: 17,
  },
  {
    id: 25,
    dept: 25,
    dept_name: "Doubs",
    elevation: 36,
    azimuth: 9,
  },
  {
    id: 26,
    dept: 26,
    dept_name: "Drome",
    elevation: 38,
    azimuth: 11,
  },
  {
    id: 27,
    dept: 27,
    dept_name: "Eure",
    elevation: 33,
    azimuth: 16,
  },
  {
    id: 28,
    dept: 28,
    dept_name: "Eure et Loire",
    elevation: 33,
    azimuth: 15,
  },
  {
    id: 29,
    dept: 29,
    dept_name: "Finistère",
    elevation: 32,
    azimuth: 22,
  },
  {
    id: 30,
    dept: 30,
    dept_name: "Gard",
    elevation: 39,
    azimuth: 12,
  },
  {
    id: 31,
    dept: 31,
    dept_name: "Haute Garonne",
    elevation: 39,
    azimuth: 17,
  },
  {
    id: 32,
    dept: 32,
    dept_name: "Gers",
    elevation: 38,
    azimuth: 18,
  },
  {
    id: 33,
    dept: 33,
    dept_name: "Gironde",
    elevation: 36,
    azimuth: 19,
  },
  {
    id: 34,
    dept: 34,
    dept_name: "Hérault",
    elevation: 39,
    azimuth: 14,
  },
  {
    id: 35,
    dept: 35,
    dept_name: "Ille et Vilaine",
    elevation: 33,
    azimuth: 19,
  },
  {
    id: 36,
    dept: 36,
    dept_name: "Indre",
    elevation: 35,
    azimuth: 16,
  },
  {
    id: 37,
    dept: 37,
    dept_name: "Indre et Loire",
    elevation: 34,
    azimuth: 17,
  },
  {
    id: 38,
    dept: 38,
    dept_name: "Isère",
    elevation: 37,
    azimuth: 10,
  },
  {
    id: 39,
    dept: 39,
    dept_name: "Jura",
    elevation: 36,
    azimuth: 10,
  },
  {
    id: 40,
    dept: 40,
    dept_name: "Landes",
    elevation: 37,
    azimuth: 20,
  },
  {
    id: 41,
    dept: 41,
    dept_name: "Loire et Cher",
    elevation: 34,
    azimuth: 16,
  },
  {
    id: 42,
    dept: 42,
    dept_name: "Loire",
    elevation: 37,
    azimuth: 12,
  },
  {
    id: 43,
    dept: 43,
    dept_name: "Haute Loire",
    elevation: 38,
    azimuth: 10,
  },
  {
    id: 44,
    dept: 44,
    dept_name: "Loire Atlantique",
    elevation: 34,
    azimuth: 19,
  },
  {
    id: 45,
    dept: 45,
    dept_name: "Loiret",
    elevation: 34,
    azimuth: 14,
  },
  {
    id: 46,
    dept: 46,
    dept_name: "Lot",
    elevation: 37,
    azimuth: 16,
  },
  {
    id: 47,
    dept: 47,
    dept_name: "Lot et Garonne",
    elevation: 37,
    azimuth: 18,
  },
  {
    id: 48,
    dept: 48,
    dept_name: "Lozère",
    elevation: 38,
    azimuth: 13,
  },
  {
    id: 49,
    dept: 49,
    dept_name: "Maine et Loire",
    elevation: 34,
    azimuth: 18,
  },
  {
    id: 50,
    dept: 50,
    dept_name: "Manche",
    elevation: 32,
    azimuth: 19,
  },
  {
    id: 51,
    dept: 51,
    dept_name: "Marne",
    elevation: 33,
    azimuth: 12,
  },
  {
    id: 52,
    dept: 52,
    dept_name: "Haute Marne",
    elevation: 34,
    azimuth: 11,
  },
  {
    id: 53,
    dept: 53,
    dept_name: "Mayenne",
    elevation: 33,
    azimuth: 18,
  },
  {
    id: 54,
    dept: 54,
    dept_name: "Meurthe et Moselle",
    elevation: 33,
    azimuth: 12,
  },
  {
    id: 55,
    dept: 55,
    dept_name: "Meuse",
    elevation: 33,
    azimuth: 10,
  },
  {
    id: 56,
    dept: 56,
    dept_name: "Morbihan",
    elevation: 33,
    azimuth: 21,
  },
  {
    id: 57,
    dept: 57,
    dept_name: "Moselle",
    elevation: 33,
    azimuth: 9,
  },
  {
    id: 58,
    dept: 58,
    dept_name: "Nièvre",
    elevation: 35,
    azimuth: 13,
  },
  {
    id: 59,
    dept: 59,
    dept_name: "Nord",
    elevation: 31,
    azimuth: 13,
  },
  {
    id: 60,
    dept: 60,
    dept_name: "Oise",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 61,
    dept: 61,
    dept_name: "Orne",
    elevation: 33,
    azimuth: 17,
  },
  {
    id: 62,
    dept: 62,
    dept_name: "Pas de Calais",
    elevation: 31,
    azimuth: 14,
  },
  {
    id: 63,
    dept: 63,
    dept_name: "Puy de Dôme",
    elevation: 37,
    azimuth: 14,
  },
  {
    id: 64,
    dept: 64,
    dept_name: "Pyrénées Atlantique",
    elevation: 38,
    azimuth: 20,
  },
  {
    id: 65,
    dept: 65,
    dept_name: "Hautes Pyrénées",
    elevation: 39,
    azimuth: 19,
  },
  {
    id: 66,
    dept: 66,
    dept_name: "Pyrénées Orientales",
    elevation: 40,
    azimuth: 16,
  },
  {
    id: 67,
    dept: 67,
    dept_name: "Bas Rhin",
    elevation: 34,
    azimuth: 8,
  },
  {
    id: 68,
    dept: 68,
    dept_name: "Haut Rhin",
    elevation: 35,
    azimuth: 8,
  },
  {
    id: 69,
    dept: 69,
    dept_name: "Rhône",
    elevation: 37,
    azimuth: 12,
  },
  {
    id: 70,
    dept: 70,
    dept_name: "Haute Saône",
    elevation: 35,
    azimuth: 9,
  },
  {
    id: 71,
    dept: 71,
    dept_name: "Saône et Loire",
    elevation: 36,
    azimuth: 12,
  },
  {
    id: 72,
    dept: 72,
    dept_name: "Sarthe",
    elevation: 33,
    azimuth: 17,
  },
  {
    id: 73,
    dept: 73,
    dept_name: "Savoie",
    elevation: 37,
    azimuth: 9,
  },
  {
    id: 74,
    dept: 74,
    dept_name: "Haute Savoie",
    elevation: 37,
    azimuth: 9,
  },
  {
    id: 75,
    dept: 75,
    dept_name: "Paris",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 76,
    dept: 76,
    dept_name: "Seine Maritime",
    elevation: 32,
    azimuth: 16,
  },
  {
    id: 77,
    dept: 77,
    dept_name: "Seine et Marne",
    elevation: 33,
    azimuth: 13,
  },
  {
    id: 78,
    dept: 78,
    dept_name: "Yvelines",
    elevation: 33,
    azimuth: 15,
  },
  {
    id: 79,
    dept: 79,
    dept_name: "Deux-sèvres",
    elevation: 35,
    azimuth: 18,
  },
  {
    id: 80,
    dept: 80,
    dept_name: "Somme",
    elevation: 32,
    azimuth: 14,
  },
  {
    id: 81,
    dept: 81,
    dept_name: "Tarn",
    elevation: 38,
    azimuth: 16,
  },
  {
    id: 82,
    dept: 82,
    dept_name: "Tarn et Garonne",
    elevation: 38,
    azimuth: 17,
  },
  {
    id: 83,
    dept: 83,
    dept_name: "Var",
    elevation: 39,
    azimuth: 10,
  },
  {
    id: 84,
    dept: 84,
    dept_name: "Vaucluse",
    elevation: 39,
    azimuth: 11,
  },
  {
    id: 85,
    dept: 85,
    dept_name: "Vendée",
    elevation: 35,
    azimuth: 19,
  },
  {
    id: 86,
    dept: 86,
    dept_name: "Vienne",
    elevation: 35,
    azimuth: 17,
  },
  {
    id: 87,
    dept: 87,
    dept_name: "Haute Vienne",
    elevation: 37,
    azimuth: 14,
  },
  {
    id: 88,
    dept: 88,
    dept_name: "Vosges",
    elevation: 34,
    azimuth: 9,
  },
  {
    id: 89,
    dept: 89,
    dept_name: "Yonne",
    elevation: 34,
    azimuth: 13,
  },
  {
    id: 90,
    dept: 90,
    dept_name: "Belfort",
    elevation: 35,
    azimuth: 8,
  },
  {
    id: 91,
    dept: 91,
    dept_name: "Essonne",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 92,
    dept: 92,
    dept_name: "Haut de Seine",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 93,
    dept: 93,
    dept_name: "Seine Saint Denis",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 94,
    dept: 94,
    dept_name: "Val de Marne",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 95,
    dept: 95,
    dept_name: "Val d'Oise",
    elevation: 33,
    azimuth: 14,
  }
];

export const INFOSEUTELSAT: Infosatellite[] = [
  {
    id: 1,
    dept: 1,
    dept_name: "Ain",
    elevation: 36,
    azimuth: 14,
  },
  {
    id: 2,
    dept: 2,
    dept_name: "Aines",
    elevation: 33,
    azimuth: 11,
  },
  {
    id: 3,
    dept: 3,
    dept_name: "Allier",
    elevation: 36,
    azimuth: 11,
  },
  {
    id: 4,
    dept: 4,
    dept_name: "Alpes Hautes Provence",
    elevation: 38,
    azimuth: 16,
  },
  {
    id: 5,
    dept: 5,
    dept_name: "Hautes Alpes",
    elevation: 37,
    azimuth: 16,
  },
  {
    id: 6,
    dept: 6,
    dept_name: "Alpes Maritimes",
    elevation: 38,
    azimuth: 12,
  },
  {
    id: 7,
    dept: 7,
    dept_name: "Ardèche",
    elevation: 38,
    azimuth: 13,
  },
  {
    id: 8,
    dept: 8,
    dept_name: "Ardennes",
    elevation: 32,
    azimuth: 13,
  },
  {
    id: 9,
    dept: 9,
    dept_name: "Ariège",
    elevation: 40,
    azimuth: 9,
  },
  {
    id: 10,
    dept: 10,
    dept_name: "Aube",
    elevation: 34,
    azimuth: 12,
  },
  {
    id: 11,
    dept: 11,
    dept_name: "Aude",
    elevation: 40,
    azimuth: 11,
  },
  {
    id: 12,
    dept: 12,
    dept_name: "Aveyron",
    elevation: 38,
    azimuth: 11,
  },
  {
    id: 13,
    dept: 13,
    dept_name: "Bouches du Rhône",
    elevation: 39,
    azimuth: 14,
  },
  {
    id: 14,
    dept: 14,
    dept_name: "Calvados",
    elevation: 34,
    azimuth: 5,
  },
  {
    id: 15,
    dept: 15,
    dept_name: "Cantal",
    elevation: 37,
    azimuth: 11,
  },
  {
    id: 16,
    dept: 16,
    dept_name: "Charente",
    elevation: 37,
    azimuth: 7,
  },
  {
    id: 17,
    dept: 17,
    dept_name: "Charente Maritime",
    elevation: 37,
    azimuth: 8,
  },
  {
    id: 18,
    dept: 18,
    dept_name: "Cher",
    elevation: 35,
    azimuth: 10,
  },
  {
    id: 19,
    dept: 19,
    dept_name: "Corrèze",
    elevation: 37,
    azimuth: 9,
  },
  {
    id: 20,
    dept: 20,
    dept_name: "Corse",
    elevation: 40,
    azimuth: 19,
  },
  {
    id: 21,
    dept: 21,
    dept_name: "Côtes d'Or",
    elevation: 35,
    azimuth: 13,
  },
  {
    id: 22,
    dept: 22,
    dept_name: "Côtes d'Armor",
    elevation: 34,
    azimuth: 3,
  },
  {
    id: 23,
    dept: 23,
    dept_name: "Creuse",
    elevation: 37,
    azimuth: 10,
  },
  {
    id: 24,
    dept: 24,
    dept_name: "Dordogne",
    elevation: 38,
    azimuth: 8,
  },
  {
    id: 25,
    dept: 25,
    dept_name: "Doubs",
    elevation: 35,
    azimuth: 15,
  },
  {
    id: 26,
    dept: 26,
    dept_name: "Drome",
    elevation: 38,
    azimuth: 14,
  },
  {
    id: 27,
    dept: 27,
    dept_name: "Eure",
    elevation: 33,
    azimuth: 8,
  },
  {
    id: 28,
    dept: 28,
    dept_name: "Eure et Loire",
    elevation: 34,
    azimuth: 9,
  },
  {
    id: 29,
    dept: 29,
    dept_name: "Finistère",
    elevation: 34,
    azimuth: 1,
  },
  {
    id: 30,
    dept: 30,
    dept_name: "Gard",
    elevation: 38,
    azimuth: 13,
  },
  {
    id: 31,
    dept: 31,
    dept_name: "Haute Garonne",
    elevation: 40,
    azimuth: 9,
  },
  {
    id: 32,
    dept: 32,
    dept_name: "Gers",
    elevation: 39,
    azimuth: 8,
  },
  {
    id: 33,
    dept: 33,
    dept_name: "Gironde",
    elevation: 38,
    azimuth: 6,
  },
  {
    id: 34,
    dept: 34,
    dept_name: "Hérault",
    elevation: 39,
    azimuth: 12,
  },
  {
    id: 35,
    dept: 35,
    dept_name: "Ille et Vilaine",
    elevation: 35,
    azimuth: 4,
  },
  {
    id: 36,
    dept: 36,
    dept_name: "Indre",
    elevation: 36,
    azimuth: 9,
  },
  {
    id: 37,
    dept: 37,
    dept_name: "Indre et Loire",
    elevation: 35,
    azimuth: 8,
  },
  {
    id: 38,
    dept: 38,
    dept_name: "Isère",
    elevation: 37,
    azimuth: 15,
  },
  {
    id: 39,
    dept: 39,
    dept_name: "Jura",
    elevation: 35,
    azimuth: 15,
  },
  {
    id: 40,
    dept: 40,
    dept_name: "Landes",
    elevation: 39,
    azimuth: 6,
  },
  {
    id: 41,
    dept: 41,
    dept_name: "Loire et Cher",
    elevation: 35,
    azimuth: 8,
  },
  {
    id: 42,
    dept: 42,
    dept_name: "Loire",
    elevation: 37,
    azimuth: 13,
  },
  {
    id: 43,
    dept: 43,
    dept_name: "Haute Loire",
    elevation: 37,
    azimuth: 15,
  },
  {
    id: 44,
    dept: 44,
    dept_name: "Loire Atlantique",
    elevation: 36,
    azimuth: 5,
  },
  {
    id: 45,
    dept: 45,
    dept_name: "Loiret",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 46,
    dept: 46,
    dept_name: "Lot",
    elevation: 38,
    azimuth: 9,
  },
  {
    id: 47,
    dept: 47,
    dept_name: "Lot et Garonne",
    elevation: 39,
    azimuth: 8,
  },
  {
    id: 48,
    dept: 48,
    dept_name: "Lozère",
    elevation: 38,
    azimuth: 12,
  },
  {
    id: 49,
    dept: 49,
    dept_name: "Maine et Loire",
    elevation: 35,
    azimuth: 6,
  },
  {
    id: 50,
    dept: 50,
    dept_name: "Manche",
    elevation: 34,
    azimuth: 5,
  },
  {
    id: 51,
    dept: 51,
    dept_name: "Marne",
    elevation: 33,
    azimuth: 12,
  },
  {
    id: 52,
    dept: 52,
    dept_name: "Haute Marne",
    elevation: 34,
    azimuth: 13,
  },
  {
    id: 53,
    dept: 53,
    dept_name: "Mayenne",
    elevation: 34,
    azimuth: 6,
  },
  {
    id: 54,
    dept: 54,
    dept_name: "Meurthe et Moselle",
    elevation: 33,
    azimuth: 12,
  },
  {
    id: 55,
    dept: 55,
    dept_name: "Meuse",
    elevation: 33,
    azimuth: 14,
  },
  {
    id: 56,
    dept: 56,
    dept_name: "Morbihan",
    elevation: 35,
    azimuth: 3,
  },
  {
    id: 57,
    dept: 57,
    dept_name: "Moselle",
    elevation: 33,
    azimuth: 15,
  },
  {
    id: 58,
    dept: 58,
    dept_name: "Nièvre",
    elevation: 35,
    azimuth: 11,
  },
  {
    id: 59,
    dept: 59,
    dept_name: "Nord",
    elevation: 31,
    azimuth: 10,
  },
  {
    id: 60,
    dept: 60,
    dept_name: "Oise",
    elevation: 33,
    azimuth: 10,
  },
  {
    id: 61,
    dept: 61,
    dept_name: "Orne",
    elevation: 34,
    azimuth: 7,
  },
  {
    id: 62,
    dept: 62,
    dept_name: "Pas de Calais",
    elevation: 32,
    azimuth: 9,
  },
  {
    id: 63,
    dept: 63,
    dept_name: "Puy de Dôme",
    elevation: 37,
    azimuth: 11,
  },
  {
    id: 64,
    dept: 64,
    dept_name: "Pyrénées Atlantique",
    elevation: 40,
    azimuth: 6,
  },
  {
    id: 65,
    dept: 65,
    dept_name: "Hautes Pyrénées",
    elevation: 40,
    azimuth: 7,
  },
  {
    id: 66,
    dept: 66,
    dept_name: "Pyrénées Orientales",
    elevation: 40,
    azimuth: 11,
  },
  {
    id: 67,
    dept: 67,
    dept_name: "Bas Rhin",
    elevation: 33,
    azimuth: 16,
  },
  {
    id: 68,
    dept: 68,
    dept_name: "Haut Rhin",
    elevation: 34,
    azimuth: 16,
  },
  {
    id: 69,
    dept: 69,
    dept_name: "Rhône",
    elevation: 37,
    azimuth: 14,
  },
  {
    id: 70,
    dept: 70,
    dept_name: "Haute Saône",
    elevation: 34,
    azimuth: 15,
  },
  {
    id: 71,
    dept: 71,
    dept_name: "Saône et Loire",
    elevation: 36,
    azimuth: 13,
  },
  {
    id: 72,
    dept: 72,
    dept_name: "Sarthe",
    elevation: 35,
    azimuth: 7,
  },
  {
    id: 73,
    dept: 73,
    dept_name: "Savoie",
    elevation: 36,
    azimuth: 16,
  },
  {
    id: 74,
    dept: 74,
    dept_name: "Haute Savoie",
    elevation: 36,
    azimuth: 15,
  },
  {
    id: 75,
    dept: 75,
    dept_name: "Paris",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 76,
    dept: 76,
    dept_name: "Seine Maritime",
    elevation: 33,
    azimuth: 8,
  },
  {
    id: 77,
    dept: 77,
    dept_name: "Seine et Marne",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 78,
    dept: 78,
    dept_name: "Yvelines",
    elevation: 34,
    azimuth: 9,
  },
  {
    id: 79,
    dept: 79,
    dept_name: "Deux-sèvres",
    elevation: 37,
    azimuth: 6,
  },
  {
    id: 80,
    dept: 80,
    dept_name: "Somme",
    elevation: 32,
    azimuth: 9,
  },
  {
    id: 81,
    dept: 81,
    dept_name: "Tarn",
    elevation: 39,
    azimuth: 10,
  },
  {
    id: 82,
    dept: 82,
    dept_name: "Tarn et Garonne",
    elevation: 39,
    azimuth: 9,
  },
  {
    id: 83,
    dept: 83,
    dept_name: "Var",
    elevation: 39,
    azimuth: 16,
  },
  {
    id: 84,
    dept: 84,
    dept_name: "Vaucluse",
    elevation: 38,
    azimuth: 14,
  },
  {
    id: 85,
    dept: 85,
    dept_name: "Vendée",
    elevation: 36,
    azimuth: 5,
  },
  {
    id: 86,
    dept: 86,
    dept_name: "Vienne",
    elevation: 36,
    azimuth: 8,
  },
  {
    id: 87,
    dept: 87,
    dept_name: "Haute Vienne",
    elevation: 37,
    azimuth: 11,
  },
  {
    id: 88,
    dept: 88,
    dept_name: "Vosges",
    elevation: 33,
    azimuth: 15,
  },
  {
    id: 89,
    dept: 89,
    dept_name: "Yonne",
    elevation: 34,
    azimuth: 11,
  },
  {
    id: 90,
    dept: 90,
    dept_name: "Belfort",
    elevation: 34,
    azimuth: 16,
  },
  {
    id: 91,
    dept: 91,
    dept_name: "Essonne",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 92,
    dept: 92,
    dept_name: "Haut de Seine",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 93,
    dept: 93,
    dept_name: "Seine Saint Denis",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 94,
    dept: 94,
    dept_name: "Val de Marne",
    elevation: 34,
    azimuth: 10,
  },
  {
    id: 95,
    dept: 95,
    dept_name: "Val d'Oise",
    elevation: 34,
    azimuth: 10,
  }
];
