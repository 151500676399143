import React from 'react';

const Footer = () => {
  return (
    <>
      <footer className="footer bg-dark" role="contentinfo">
        <div>
          <p className="footer-text">Copyright &copy; Lamivic 2022</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
