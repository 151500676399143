import React from 'react'

function BreadcrumbLevel2(props:any) {
    return (
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="../">Accueil</a>
            </li>
            <li className="breadcrumb-item" >
              <a href={props.referent_path}>{props.referent_title}</a>
            </li>
            <li className="breadcrumb-item active">{props.title}</li>
          </ol>
    )
}

export default BreadcrumbLevel2
