import Satellite from "../satellite";

export const SATELLITES: Satellite[] = [
  {
    id: 1,
    name: "ASTRA",
    name_breadcrumb: "Astra",
    nav: "astra",
    orbital: "19,2° EST",
    pire: "astra.gif",
    searchparam: "astra",
    tvchannels: "CANAL+",
    website: "https://www.ses.com/fr/europe/video-en-europe",
    baseline: "",
  },
  {
    id: 2,
    name: "HOTBIRD",
    name_breadcrumb: "Hotbird",
    nav: "hotbird",
    orbital: "13° EST",
    pire: "hotbird.gif",
    searchparam: "hotbird",
    tvchannels: "BIS",
    website: "https://www.eutelsat.com/fr/satellites/eutelsat-13-est.html",
    baseline: "",
  },
  {
    id: 3,
    name: "EUTELSAT",
    name_breadcrumb: "Eutelsat",
    nav: "eutelsat",
    orbital: "5° OUEST",
    pire: "ab3.gif",
    searchparam: "eutelsat",
    tvchannels: "BIS et FRANSAT",
    website: "https://www.eutelsat.com/fr/satellites/eutelsat-5-ouest.html",
    baseline: "Anciennement nommé TELECOM 2C puis ATLANTIC BIRD 3.",
  },
];
