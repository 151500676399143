import React, { FunctionComponent } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Tabs, Tab } from "react-bootstrap";
import TabPosecable from "../../components/Tabs/TabPosecable";
import TabPoseinstallation from "../../components/Tabs/TabPoseinstallation";
import TabPosemontage from "../../components/Tabs/TabPosemontage";
import { useParams } from "react-router-dom";

const Pose: FunctionComponent = () => {
  const { nav } = useParams();

  return (
    <div className="container">
      <Breadcrumb title_page="La pose - les 3 étapes" title="La pose" />

      {/* EDITO DE LA PAGE */}
      <div className="row">
        <div className="col-lg-12 mb-3">
          La pose s'effectue en 3 étapes : le montage, l'installation et le
          câble.
          <br />
          Selectionnez l'étape désirée : <br />
          <br />
        </div>
        <div className="col-lg-12">
          <Tabs
            defaultActiveKey={nav !== undefined ? nav : "montage"}
            className="nav nav-fill mb-3 onglet"
            role="tablist"
            aria-label="La pose"
          >
            <Tab
              eventKey="montage"
              id="montage"
              title="Le prémontage"
              role="tab"
              aria-selected="false"
              aria-controls="premontage-tab"
            >
              <TabPosemontage />
            </Tab>
            <Tab
              eventKey="installation"
              id="installation"
              title="L'installation"
              role="tab"
              aria-selected="false"
              aria-controls="installation-tab"
            >
              <TabPoseinstallation />
            </Tab>
            <Tab
              eventKey="cable"
              id="cable"
              title="Le câble"
              role="tab"
              aria-selected="false"
              aria-controls="cable-tab"
            >
              <TabPosecable />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Pose;
