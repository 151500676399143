import React from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  // NavDropdown,
  Container,
} from "react-bootstrap";

const Header = () => {
  return (
    <header>
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          fixed="top"
        >
          <Container>
            <NavbarBrand href="/">Tout sur la parabole</NavbarBrand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto-left">
                <Nav.Link href="/">Accueil</Nav.Link>
                <Nav.Link href="/droit">Le droit à l'antenne</Nav.Link>
                <Nav.Link href="/installation">L'installation</Nav.Link>
                <Nav.Link href="/pose">La pose</Nav.Link>
                <Nav.Link href="/conseil">Les conseils</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
