import React, { FunctionComponent, useState, useEffect } from "react";
import { Departements } from "../../models/france";
import ResultCard from "../../components/Cards/ResultCard";
import Satellite from "../../models/satellite";

type PROPS = {
  satellite: Satellite;
};

const SearchCard: FunctionComponent<PROPS> = ({ satellite }) => {
  const [select, setSelect] = useState({ selectedValue: "0" });
  const [infodept, setInfodept] = useState(0);
  const dept_infos = Departements;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSelect({ selectedValue: e.target.value });
    const dept = Number(e.target.value);
    setInfodept(dept);
  };

  return (
    <div className="col-lg-12 card h-100 mb-3">
      <div className="card-body">
        <p className="card-text">
          <h3>
            Azimuth et élévation
          </h3>
        </p>

        <form>
          <label>
            Séléctionner votre département pour le satellite :
            <select
              id="choix_dept"
              className="custom-select custom-select-lg mt-2 mb-2"
              value={select.selectedValue}
              onChange={(e) => handleChange(e)}
            >
              <option value="0">-</option>
              {dept_infos.map((deptinfo) => (
                <option key={deptinfo.dept} value={deptinfo.dept}>
                  {deptinfo.dept} - {deptinfo.dept_name}
                </option>
              ))}
            </select>
          </label>
        </form>
        {infodept != 0 ? (
          <ResultCard dept={infodept} satname={satellite.nav} />
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default SearchCard;
