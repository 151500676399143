import React, { FunctionComponent } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Tabs, Tab } from "react-bootstrap";
import TabParabole from "../../components/Tabs/TabParabole";
import TabFiche from "../../components/Tabs/TabFiche";
import TabLNB from "../../components/Tabs/TabLNB";
import TabFixation from "../../components/Tabs/TabFixation";
import TabCable from "../../components/Tabs/TabCable";
import { useParams } from "react-router-dom";

const Materiel: FunctionComponent = () => {
  const { nav } = useParams();

  return (
    <div className="container">
      <Breadcrumb
        title_page="Le matériel nécessaire"
        title="Le matériel"
        referent_title="Les conseils"
        referent_path="/conseil"
      />

      {/* EDITO DE LA PAGE */}
      <div className="row">
        <div className="col-lg-12 mb-3">
          Selectionnez le matériel désiré :<br />
        </div>
        <div className="col-lg-12">
          <Tabs
            defaultActiveKey={nav !== undefined ? nav : "parabole"}
            id="materiel"
            className="nav nav-fill mb-3"
            role="tablist"
            aria-label="Le matériel"
          >
            <Tab
              eventKey="parabole"
              title="Parabole"
              role="tab"
              aria-selected="false"
              aria-controls="parabole-tab"
            >
              <TabParabole />
            </Tab>
            <Tab
              eventKey="LNB"
              title="Tête LNB"
              role="tab"
              aria-selected="false"
              aria-controls="LNB-tab"
            >
              <TabLNB />
            </Tab>
            <Tab
              eventKey="fixation"
              title="Fixation"
              role="tab"
              aria-selected="false"
              aria-controls="fixation-tab"
            >
              <TabFixation />
            </Tab>
            <Tab
              eventKey="cable"
              title="Câble"
              role="tab"
              aria-selected="false"
              aria-controls="cable-tab"
            >
              <TabCable />
            </Tab>
            <Tab
              eventKey="fiche"
              title="Fiche F"
              role="tab"
              aria-selected="false"
              aria-controls="parabole-tab"
            >
              <TabFiche />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Materiel;
