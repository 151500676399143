import React, { FunctionComponent, useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import InstallCard from '../../components/Cards/InstallCard';
// import SatelliteService from "../../services/satellite-service";
import Satellite from '../../models/satellite';
import { SATELLITES } from '../../models/database/mock-satellites';

const Installation: FunctionComponent = () => {
  const [satellites, setSatellites] = useState<Satellite[]>([]);
  useEffect(() => {
    setSatellites(SATELLITES);
    //   SatelliteService.getSatellites().then((satellites) =>
    //     setSatellites(satellites)
    //   );
  }, []);

  return (
    <div className="container">
      <Breadcrumb
        title_page="L'installation satellite"
        title="Installation : choix du satellite"
        referent_title=""
        referent_path=""
      />
      {/* EDITO DE LA PAGE */}
      <div className="row">
        <div className="col-lg-12 mb-12">
          Afin d'orienter correctement une parabole, vous devez connaître la
          taille minimum, l'azimuth et l'élévation pour celle-ci en fonction du
          satellite d&eacute;sir&eacute;.
          <br />
          Pour cela, sélectionnez votre satellite :<br />
          <br />
        </div>

        {satellites.map((satellite) => (
          <InstallCard key={satellite.id} satellite={satellite} />
        ))}
      </div>
    </div>
  );
};

export default Installation;
