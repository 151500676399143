import React, { FunctionComponent } from 'react';

const Landing: FunctionComponent = () => {
  return (
    <div className="container">
      <h1 className="my-4">Tout sur la parabole</h1>

      {/* Content Row */}
      <div className="row">
        <div className="col-lg-12">
          <p>
            Vous désirez savoir comment poser une parabole, avoir tout les
            paramètres nécessaires afin d'avoir les chaînes que vous souhaitez
            regarder, alors vous êtes au bon endroit : droit à l'antenne,
            conseils de pose, matériel nécessaire, branchements, aide au
            dépannage...
          </p>
          <p>
            Vous trouverez aussi les coordonnées du satellite Astra 19,2°
            (CANAL+), de Hotbird 13° (anciennement TPS) et de Atlantic Bird 3
            (BIS et FRANSAT).
          </p>
          <p>
            Des rubriques sur les zones de couvertures de ces satellites et les
            infos importantes sont accessible également : Le tout GRATUITEMENT.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Landing;
