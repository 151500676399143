import React, { FunctionComponent } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const Outils: FunctionComponent = () => {
  return (
    <div className="container">
      <Breadcrumb
        title_page="Les outils nécessaires"
        title="Les outils"
        referent_title="Les conseils"
        referent_path="/conseil"
      />

      {/* EDITO DE LA PAGE */}
      <div className="row">
        <div className="col-lg-12">
          Voici le mat&eacute;riel pr&eacute;conis&eacute; pour l'installation :
        </div>
        <div className="col-lg-12">
          <ul>
            <li>1 boussole</li>
            <li>1 niveau</li>
            <li>1 perceuse</li>
            <li>1 tournevis cruciforme</li>
            <li>1 tournevis plat</li>
            <li>1 marteau</li>
            <li>1 cutter</li>
            <li>1 clé de 13</li>
            <li>4 vis ( diamètre 8 millimètres )</li>
            <li>
              4 chevilles, adaptéé au matériau du mur où sera fixée l'antenne
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Outils;
