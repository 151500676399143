import React, { FunctionComponent } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import img_materiel from "../../assets/images/conseil/materiel.gif";
import img_outils from "../../assets/images/conseil/outils.gif";

const Conseil: FunctionComponent = () => {
  return (
    <div className="container">
      <Breadcrumb
        title_page="Les conseils d'une pose reussie"
        title="Les conseils"
      />

      {/* EDITO DE LA PAGE */}
      <div className="row">
        <div className="col-lg-12">
          <p>
            Le meilleur conseil qu'on pourrait donner : &quot;{" "}
            <strong>prendre son temps</strong> &quot;.
            <br />
            Ensuite, il faut y aller &eacute;tape par &eacute;tapes !!
          </p>
        </div>
        <div className="col-lg-6 mb-4">
          <div className="card h-100">
            <h2 className="card-header">Le matériel</h2>
            <div className="card-body">
              <p className="card-text">
                Parabole - Tête LNB - Fixation - Câble - Fiche F...
              </p>
              <p className="text-center">
                <a href="conseil/materiel">
                  <img
                    src={img_materiel}
                    alt="le matériel"
                    className="img-hover"
                    role="link"
                    width="210px"
                    height="210px"
                  />
                </a>
              </p>
            </div>
            <div className="card-footer text-center">
              <a href="conseil/materiel" className="btn btn-primary">
                Choisir
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-5">
          <div className="card h-100">
            <h2 className="card-header">Les outils</h2>
            <div className="card-body">
              <p className="card-text">Les outils nécessaires...</p>
              <p className="text-center">
                <a href="conseil/outils">
                  <img
                    src={img_outils}
                    alt="les outils"
                    className="img-hover"
                    role="link"
                    width="210px"
                    height="210px"
                  />
                </a>
              </p>
            </div>
            <div className="card-footer text-center">
              <a href="conseil/outils" className="btn btn-primary">
                Choisir
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conseil;
